.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .agent {
    padding-top: var(--TotalHeaderHeight);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .nonAgent {
    padding-top: var(--HeaderHeight);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
