@use '../../assets/styles/mixins' as mixin;


.btnGroup {
    position: fixed;
    top: 140px;
    right: 0px;
    z-index: 3;

    display: none;

    @include mixin.lg-ut {
        display: flex;
    }

    flex-direction: column;
    align-items: end;
    pointer-events: none;

    button {
        pointer-events: auto;
        margin-bottom: 10px;
        font-size: medium;
        color: var(--AsideBtnTexteColor);

        transition: 0.2s opacity;
        box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.44);

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    .btnFirst {
        background-color: var(--AsideBtnFirstBgColor);
        border: 2px solid var(--AsideBtnFirstBgColor);
        padding: 3px 10px;
    }
    .btnSecondary {
        // background-color: var(--AsideBtnSecondaryBgColor);
        background-color: var(--AsideBtnSecondaryBgColor);
        // border: 2px solid var(--AsideBtnSecondaryBgColor);
        border: 2px solid var(--AsideBtnSecondaryBgColor);
        padding: 3px 10px;
        text-transform: capitalize;


    }

    .btnSecondaryBoatName {
        font-weight: bold;
    }
    .btnSecondaryBoatName {
        font-weight: bold;
    }
    .btnSecondaryBoatModel {
        font-weight: small;
    }

    .btnOther {
        background-color: var(--AsideBtnOtherBgColor);
        border: 2px solid var(--AsideBtnOtherBgColor);
        padding: 3px 10px;
    }
    
}
