@mixin sm-ut {
  @media (min-width: 577px) {
    @content;
  }
}

@mixin md-ut {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-ut {
  @media (min-width: 993px) {
    @content;
  }
}

@mixin xl-ut {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin xxl-ut {
  @media (min-width: 1501px) {
    @content;
  }
}

@mixin xxxl-ut {
  @media (min-width: 1670px) {
    @content;
  }
}

// New standard ?
// Desktop: Above 1440px
// Laptop: 992px to 1440px
// Tablet: 576px to 992px
// Mobile: Up to 576px
