/*
  Fichiers variables.scss par marque.
   Chaque fichier doit avoir les mêmes variables, seulement les couleurs de la marque peuvent changer.
   Si une variable doit être ajoutée sur une déclinaison du site, cette même variable doit être ajoutée aux autres déclinaisons.

   Merci de respecter le format suivant:

   // NomDuComposant
   --NomDuComposantTextColor

   Le nom variable doit commencer par le nom du composant, et décrire à quelle sert.
   (Quitte à avoir un nom assez long)
*/

.allset_brand {

    // Couleurs de la marque
    --primary: rgb(21, 83, 120);
    --secondary: rgb(52, 117, 143);
    --third: rgb(103, 167, 177);
    --fourth: rgb(137, 204, 202);
    --fifth: rgb(245, 244, 244);
  
    // Global
    --inputBg: rgb(235, 235, 235);
    --inputFieldRequired: red;
    --inputfieldRequiredTextColor: var(--third);
  
    // Header
    --HeaderButtons: var(--primary);
    --HeaderUserNameText: var(--primary);
  
    --HeaderWarningProBg: red;
    --HeaderWarningProColor: var(--fifth);
  
    --HeaderMenuXsTextColor1: black; // Xs = Version mobile/tablette
    --HeaderMenuXsTextColor2: black;
  
    --HeaderHoverLiColor: var(--third);
    --HeaderSubMenuBgColor: var(--fourth);
    --HeaderSubMenuTextColor: white;
    --HeaderBorderSubMenuColor: var(--fourth);

    --HeaderNumbersCartTextColor: white;
  
    // Footer
    --FooterNewsletterBg: var(--third);
    --FooterNewsletterTextColor: var(--fifth);
  
    --FooterTitleTextColor: var(--primary);
    --FooterTextColor: var(--third);
    --FooterUnderHrTextColor: var(--primary);
  
  
    // Modal
    --ModalBg: linear-gradient(var(--third), var(--primary));
    --btnConnexionBgColor: white;
    --btnConnexionTxtColor: var(--primary);
  
    //New User && Login
    --ModalTitleColor: white;
  
    // RegisterPro
    --RegisterProConfirmModalBg: var(--third);
  
    // Boats
    --BoatsIconAddBoats: var(--third);
  
    // Search
    --SearchBoatsBg: var(--third);
    --SearchBoatsTextColor: white;
  
    // BoatCard
    --BoatCardIconColor: var(--primary);
  
    // BoatModal
    --BoatModalBorder: var(--primary);
    --BoatModalBg: white;
    --BoatModalText: var(--third);
    --BoatModalIcons: var(--third);
    --BoatModalTextTitle: var(--primary);
    --BoatModalTextTypeBoat: black;
    --BoatModalTextLabel: black;
    --BoatModalTextPaiement: white;
    --BoatModalPaiementBarreColor: var(--third);
  
    //BoatRegister
    --BoatRegisterIconInfoColor: var(--fourth);
  
    // Aside
    --AsideBtnTexteColor: var(--fifth);
    --AsideBtnFirstBgColor: var(--primary);
    --AsideBtnSecondaryBgColor: var(--primary);
    --AsideBtnOtherBgColor: var(--third);
  
    // Homepage
    --HomepageSliderTextColor: var(--fifth);
    --HomepageTitleColor: var(--third);
  
    //PackCard
    --PackCardTitleColor: var(--primary);
    --PackCardTextColor: black;
    --PackCardPriceColor: var(--third);
    --PackCardAddToCartBtnBg: var(--third);
    --PackCardAddToCartBtnColor: white;
    --PackCardQuantityContainerBg: var(--primary);
    --PackCardQuantityContainerColor: white;
  
    // Pack
    --PackNameColor: var(--primary);
    --PackPriceColor: var(--third);
    --PackPriceTtcColor: var(--fourth);
    --PackDescriptionColor: black;
    --PackStrongPointsColor: var(--primary);
  
    --PackQuantityBorderColor: var(--primary);
  
    --PackAddToCartBtnBg: var(--primary);
    --PackAddToCartBtnColor: white;
  
    --PackReferenceColor: var(--primary);
  
    --PackFeatureBgColor: var(--fifth);
    --PackOhtersInfoBtnColor: var(--third);
    --PackOhtersInfoBtnBgColor: white;
  
    --PackFeatureLinkColor: var(--third);
  
    // Dashboard
    --DashboardTabActive: var(--primary);
  
    //OrdersHistory
    --OrdersHistoryTrBorderBottom: var(--primary);
  
    //OrderHistoryLine
    --OrderHistoryLineTrBorderBottom: var(--primary);
    --OrderHistoryLineOrderDateTextColor: var(--third);
    --OrderHistoryLineOrderStatusTextColor: var(--fourth);
    --OrdersHistoryYearButtonBg: var(--third);
    --OrdersHistoryYearButtonBgSelected: var(--secondary);
    --OrdersHistoryYearButtonColor: white;
  
    // Commissions
  
    --CommissionsTotalTitleColor: var(--third);
  
    // CategoryPacks
    --CategoryPacksTitleTextColor: var(--secondary);
    --SubCategoryPacksTitleTextColor: var(--third);
  
    --SubCategoryPacksMenuBgColor: white;
    --SubCategoryPacksMenuItemBgColor: var(--fifth);
    --SubCategoryPacksMenuItemHoverBgColor: var(--fourth);
  
  
    // Cart
    --CartTitleTextColor: var(--third);
    --CartTitleCartTotalTextColor: var(--fifth);
    --CartPriceBlocBgColor: linear-gradient(var(--third), var(--primary));
    --CartPriceBlocPromoBgColor: var(--fifth);
    --CartPriceBlocBorderColor: none;
    --CartPriceBlocPromoTextColor: var(--third);
    --CartPriceBlocPromoBtnOkTextColor: var(--fifth);
    --CartPriceBlocPromoBtnOkTextHoverColor: var(--fifth);
    --CartBtnValidateBgColor: white;
    --CartBtnValidateTxtColor: var(--primary);
    --CartPaiementTextColor: black;
    --CartPaiementTextColorHover: var(--third);
  
    // CartPack
    --CartPackTitleTextColor: var(--third);
    --CartPackDeleteIconColor: var(--primary);
    --CartPackQuantityBorderColor: black;
    --CartPackQuantityArrowColor: black;
    --CartPackPriceColor: var(--primary);
    --CartPackPriceTtcColor: var(--fourth);
  
    // Delivery
    --DeliveryTitleDeliveryTotalTextColor: var(--fifth);
    --DeliveryPriceBlocBgColor: var(--third);
    --DeliveryFormBgColor: var(--fifth);
    --DeliveryFormInputColor: white;
    --DeliveryFormIconColor: var(--primary);
    --DeliveryFormCheckAdresseTextColor: var(--primary);
    --DeliveryBoxBgColor: var(--fifth);
  
    // Slider
    --SliderFirstBarColor: var(--primary);
    --SliderSecondBarColor: white;
    --SliderNumberColor: var(--primary);
  
    // Notification
    --NotificationValidateBgColor: rgb(7, 146, 7);
    --NotificationTextColor: white;
  
    // Newsletter Modal
    --FooterNewsletterModalBorder: var(--primary);
    --FooterNewsletterModalBg: var(--third);
    --FooterNewsletterModalText: white;
    --FooterNewsletterModalIcons: white;
  
    // Account
    --AccountTextColor: var(--third);
    --AccountTextColorHover: var(--primary);
    --AccountMenuBg: var(--fifth);
    --AccountInputBg: white;
  
    // Allset
    --AllsetTitleTextColor: var(--third);
    --AllsetTextIconsHoverColor: var(--primary);
  
    // Team
    --TeamTitleTextColor: var(--third);
    --TeamBorderImageColor: var(--secondary);
    --TeamTextImageColor: white;
  
    // Contact
    --ContactFaqBgColor: var(--fourth);
    --ContactFaqTextColor: white;
    --ContactTitleTextColor: var(--primary);
    --ContactTitleTextColor: var(--primary);
    --ContactTitleNameAndWorkTextColor: var(--third);
    --ContactBorderImageColor: var(--primary);
  
    // DeliveryModalAddress
    --AddressModalBorder: var(--primary);
    --AddressModalBg: white;
    --AddressModalText: var(--third);
    --AddressModalIcons: var(--third);
  
    // DeliveryAddress
    --AddressSelectedBorder: var(--primary);

    //ModalPaiement
    --ModalPaiementIcon: white;
  }
  