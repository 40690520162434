@use '../../../../../assets/styles/mixins' as mixin;

.blocBoat {
    z-index: 3;
}
.imageBoat {
    max-width: 100px;
    height: auto;
    padding: 10px 0px;
}

.blocImageModel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    
    // border-bottom: 1px solid;
}

.boatNom{
    text-transform: capitalize;
    padding-bottom: 10px;
    font-size: 1.1em;
    font-weight: bold;
}

.barre{
    max-width: 30px;
    border: 1px solid;
    margin-top: 15px ;
    text-align:start;
    
}