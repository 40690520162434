:root {
    --font-family: "basic-sans", sans-serif;
    --font-family2: "Public Sans", sans-serif;
    --red: #ff0000;
    --red2: #ff5353;

    // Hauteur du menu header
    --HeaderWarningProHeight: 25px;
    --HeaderHeight: 70px;
    --TotalHeaderHeight: calc(var(--HeaderHeight) + var(--HeaderWarningProHeight))
}
