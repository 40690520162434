.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;

}
.p-30 {
    padding: 30px;
}
.m-auto{
    margin:auto;
}
.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-20 {
    margin: 20px;
}

.m-30 {
    margin: 30px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.my-30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.g-5 {
    gap: 5px;
}

.g-10 {
    gap: 10px;
}

.g-20 {
    gap: 20px;
}

.g-30 {
    gap: 30px;
}

.mx-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.fw-bold {
    font-weight: bolder;
}
