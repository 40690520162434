@use '../../../../assets/styles/mixins' as mixin;

// Header Web
.header {
    // border-bottom: 1px solid;
    a {
        display: flex;
        align-items: center;
    }

    @include mixin.lg-ut {
        display: flex;
    }

    background-color: white;
    width: 100%;
    height: var(--HeaderHeight);

    padding: 0 15px;

    justify-content: space-between;
    align-items: center;

    display: none;
    flex-flow: row;

    .logoAllset {
        width: 140px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include mixin.xl-ut {
            width: 180px;
        }
    }

    .menu1 {
        display: flex;
        flex-direction: row;
        height: 100%;

        li {
            display: flex;
            align-items: center;
            padding: 0 15px;

            color: var(--HeaderButtons);
            text-transform: uppercase;

            &:nth-child(4n) {
                display: none;
                @include mixin.xl-ut {
                    display: block;
                    display: flex;
                }
            }

            &:hover {
                color: var(--HeaderHoverLiColor);

                
            }


        }

        .logoBrand {
            width: 70px;

            &:hover {
                cursor: default;
            }
        }
    }

    .menu2 {
        display: flex;
        flex-direction: row;

        li {
            padding: 0 10px;
            color: var(--HeaderButtons);

            display: flex;
            flex-direction: row;
            align-items: center;

            &.user {
                text-transform: none;
                color: var(--HeaderUserNameText);

            }

            small {
                margin-left: 5px;
            }

            .boatInfo {
                display: flex;
                flex-direction: column;
                font-size: 13px;

                .boatName {
                    max-width: 130px;
                    margin-top: -3px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

        }

    }

    .menuParent {
        &:hover {
            // background-color: var(--HeaderSubMenuBgColor);
            background-color: white;

            .subMenu {
                display: block;

            }
        }

        .subMenu {

            display: none;

            &:hover {
                display: block;
                
            }

            // background-color: var(--HeaderSubMenuBgColor);
            background-color: white;
            padding: 15px 2px 0px;
            margin-left: -15px;
            position: absolute;
            

            top: var(--HeaderHeight);

            &.headerPro {
                top: var(--TotalHeaderHeight);
            }
            &.headerTest {
                top: 145px;
            }
            &.headerPromo {
                top: 135px;
            }
            &.headerPromoNoAgent {
                top: 110px;
            }

            border-left: 1px solid var(--HeaderBorderSubMenuColor);
            border-bottom: 1px solid var(--HeaderBorderSubMenuColor);
            border-right: 1px solid var(--HeaderBorderSubMenuColor);


            li {
                margin-bottom: 15px;
                font-size: 0.8em;
                text-transform: none;
                
                // color: var(--HeaderSubMenuTextColor);
                color: var(--HeaderBorderSubMenuColor);

                &:hover {
                    color: var(--HeaderHoverLiColor);
                    

                }
            }
        }

        

        // .subMenuFirstWidth {
        //     min-width: 131.54px;
        // }
        .subMenuOneWidth {
            min-width: 109.66px;
        }

        .subMenuTwoWidth {
            min-width: 90.42px;
        }

    }
    

    .menuYachtParent{
        @extend .menuParent;        
        &:hover {
            background-color: white;
        }

        .subMenuFirst{
            @extend .subMenu;
            background-color: white;
            
            
            li {
                color: var(--HeaderBorderSubMenuColor);

                &:hover {
                    color: var(--HeaderHoverLiColor);
                }
            }
        }
    }

    .iconFlag {
        width:17px;
    }
    .icons {
        padding:0px 5px !important;
    }

    .containerNumbersCart {
        position: relative;
        

        .numbersCart {
            border-radius: 50%;
            font-size: 12px;
            
            background-color: var(--third);
            color: var(--HeaderNumbersCartTextColor); 
        }

        .numbersCartInsert {
            animation: pulse 1s;
            @extend .numbersCart;
            
        }

        .numbersCartPaddingOne{
            padding:1px 6px;
            position: absolute;
            top:-23px;
            left:-13px;
        }
        .numbersCartPaddingTwo{
            padding:2px 6px;
            position: absolute;
            top:-24px;
            left:-14px;
        }
        .numbersCartPaddingThree{
            padding:4px 6px;
            position: absolute;
            top:-27px;
            left:-17px;
        }

        @keyframes pulse {
            0% {
                transform: scale(1.5);
                background-color: var(--primary);
                font-size:18px;

            }
            50% {
                transform: scale(1.2);
            }
            100% {
                transform: scale(1);
                background-color: var(--third);
                font-size: 12px;

            }
        }
        
    }

}

