@use '../../assets/styles/mixins' as mixin;

.footer {

    .containerFooter{
        margin: auto;
        width: 100%;

        @include mixin.sm-ut{
            max-width: 500px;
        }
        @include mixin.md-ut{
            max-width: 600px;
        }
        @include mixin.lg-ut{
            max-width: 800px;
        }
        @include mixin.xl-ut{
            max-width: 1000px;
        }
        @include mixin.xxl-ut{
            max-width: 1200px;
        }
    }

    hr {
        color: var(--FooterUnderHrTextColor);
        margin-bottom: 0px;
    }

    .containerLinks {
        display: flex;
        flex-direction: column;
        color: var(--FooterUnderHrTextColor);
        padding: 10px;
        @include mixin.md-ut{
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .copyright {
        text-align: center;
        span {
            text-decoration: underline;
            margin-left: 10px;
            @include mixin.sm-ut{
                margin-left: 20px;
            }
        }
    }

    .media {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            margin-right: 10px;
            font-size:small;
        }
        i {
            margin-left: 5px;
        }
    }

}
