body {
  margin: 0;
  font-family: var(--font-family2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--gray-2);
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
}

p {
  margin: 0;
  white-space: pre-wrap;
}
