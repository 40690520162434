.d-flex {
    display: flex;
}

.f-row {
    flex-direction: row;
}

.f-column {
    flex-direction: column;
}

.f-fill {
    flex: 1 1 auto;
}

.j-c-center {
    justify-content: center;
}

.j-c-space-between {
    justify-content: space-between;
}

.j-c-space-around {
    justify-content: space-around;
}

.a-i-center {
    align-items: center;
}

.a-i-start {
    align-items: flex-start;
}
