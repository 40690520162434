@use '../../../../assets/styles/mixins' as mixin;

// Header Mobile
.headerXs {
    @include mixin.lg-ut {
        display: none;
    }
    

    background-color: white;
    width: 100%;
    height: var(--HeaderHeight);
    padding: 0 15px;

    

    .iconAllset {
        width: 30px;
    }
    .logoBrand {
        width: 70px;

        &:hover {
            cursor: default;
        }
    }

    .iconPrestige {
        width: 70px;
    }
    .menuHeader{
        position: relative;
        top: 30%;
    }

    .logoAllset {
        display:none;
        @include mixin.sm-ut {
            display: block;
            width: 140px;
            position: absolute;
            left: 40%;
            transform: translateX(-50%);
            transform: translateY(-20%);
        }
    }
    .menuRight {
        position: absolute;
        right: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width:54%;
        @include mixin.sm-ut {
            width:33%;
        }
        @include mixin.md-ut {
            width:25%;
        }
    }
    
    .menu1 {
        color: var(--HeaderButtons);
        
        .boatBlock {
            position: absolute;
            left: 1%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .boatInfo {
                display: flex;
                flex-direction: column;
                font-size: 13px;
                
                .boatName {
                    max-width: 130px;
                    margin-top: -3px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        
        
        li {
            small {
                margin-left: 5px;
            }
            
            .closeBtn {
                font-size: 22px;
            }
        }
        
    }
    
    .menu2 {
        background-color: white;
        position: absolute;
        right: -110%;
        top: var(--TotalHeaderHeight);
        width: 100vw;
        height: 100vh;
        transition: .8s right;

        top: var(--HeaderHeight);

            &.headerPro {
                top: var(--TotalHeaderHeight);
            }
            &.headerTest {
                top: 145px;
            }
            &.headerPromo {
                top: 135px;
            }
        
        &.showMenu {
            right: 0;
        }

        .wrap {

            width: fit-content;
            margin: 0 auto;
            padding: 30px;

            @include mixin.md-ut {
                padding: 0 80px;
                display: flex;
                justify-content: space-around;
                margin: 0;
                width: 100%;
            }

            ul {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                width: 100%;
                margin: 0 auto;

                @include mixin.md-ut {
                    width: fit-content;
                }

                li {
                    text-transform: uppercase;
                    margin: 10px 0;
                    font-size: 24px;
                    color: var(--HeaderMenuXsTextColor1);

                    &.orders span {
                        display: block;
                    }
                }

                .menuChildren {

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 0 0 10px;

                    a {
                        margin-top: 7px;
                    }

                    li {
                        text-transform: none;
                        margin: 0;
                        font-size: 16px;
                        color: var(--HeaderMenuXsTextColor2);


                    }
                }
            }
        }

    }

    .iconFlag {
        width:17px;
    }
    .icons {
        display: flex;
        padding:0px 5px !important;
        margin-right: 5px;
        div {
            padding-left:10px;
            padding-top: 3px;
        }
    }

    .containerNumbersCart {
        position: relative;
        

        .numbersCart {
            border-radius: 50%;
            font-size: 12px;
            
            background-color: var(--third);
            color: var(--HeaderNumbersCartTextColor); 
        }

        .numbersCartInsert {
            animation: pulse 1s;
            @extend .numbersCart;
            
        }

        .numbersCartPaddingOne{
            padding:1px 6px;
            position: absolute;
            top:-13px;
            left:-13px;
        }
        .numbersCartPaddingTwo{
            padding:2px 6px;
            position: absolute;
            top:-14px;
            left:-14px;
        }
        .numbersCartPaddingThree{
            padding:4px 6px;
            position: absolute;
            top:-17px;
            left:-17px;
        }

        @keyframes pulse {
            0% {
                transform: scale(1.5);
                background-color: var(--primary);
                font-size:18px;

            }
            50% {
                transform: scale(1.2);
            }
            100% {
                transform: scale(1);
                background-color: var(--third);
                font-size: 12px;

            }
        }
        
    }
    
}
