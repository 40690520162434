@use '../../assets/styles/mixins' as mixin;

.headerWrap {
    position: fixed;
    z-index: 1;
    width: 100%;
}

// Warning professional account
.warningPro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--HeaderWarningProBg);
    color: var(--HeaderWarningProColor);
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    height: var(--HeaderWarningProHeight);
}

.warningTest {
    @extend .warningPro;
    background-color: rgb(247, 163, 9);
    padding: 25px 0px;
}

.HeaderPromo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--HeaderPromoBg);
    color: var(--HeaderPromoColor);
    text-align: center;
    font-weight: 300;
    height: 40px;
    display: none;
    @include mixin.lg-ut {
        display: block;
        display: flex;
    }
}

.HeaderPromoSmartphone {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--HeaderPromoBg);
    color: var(--HeaderPromoColor);
    text-align: center;
    font-weight: 300;
    height: 40px;
    cursor:pointer;
    @include mixin.lg-ut {
        display: none;
    }
}

.HeaderPromoText {
    text-transform: uppercase;
    display: none;
    @include mixin.sm-ut {
        display: block;
    }
}
.HeaderPromoTextSmartphone {
    text-transform: uppercase;
    display: block;
    @include mixin.sm-ut {
        display: none;
    }
}

.btnActionPromo {
    display: none;
    margin-left:30px;
    border: 1px solid var(--HeaderBtnColor);
    background-color: var(--HeaderBtnColor);
    color:var(--HeaderBtnBg);
    padding: 5px 20px;
    border-radius: 25px;
    cursor: pointer;

    @include mixin.lg-ut {
        display: block;
    }
    // @include mixin.xl-ut {
    //     display: none;
    // }
}