@use './mixins' as mixin;

// Button
.btn {
    padding: 8px 15px;
    cursor: pointer;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.8;
    }
}

.btn-primary {
    background-color: var(--primary);
    color: white;
    border: 2px solid var(--primary);
}

.btn-reverse-primary {
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: white;
}

.btn-secondary {
    background-color: var(--secondary);
    color: white;
    border: 2px solid var(--secondary);
}

.btn-reverse-secondary {
    border: 2px solid var(--secondary);
    color: var(--secondary);
    background-color: white;
}

.btn-third {
    background-color: var(--third);
    color: white;
    border: 2px solid var(--third);
}

.btn-reverse-third {
    border: 2px solid var(--third);
    color: var(--third);
    background-color: white;
}

.btn-fourth {
    background-color: var(--fourth);
    color: white;
    border: 2px solid var(--fourth);
}

.btn-reverse-fourth {
    border: 2px solid var(--fourth);
    color: var(--fourth);
    background-color: white;
}

.btn-sending {
    padding: 8px 15px;
    cursor: pointer;
    background-color: var(--secondary);
    color: white;
    border: 2px solid var(--secondary);
}

// Form

label {
    font-size: 14px;
    font-weight: 300;
}

input {
    border: none;
    padding: 8px 15px;
    background-color: var(--inputBg);
    border-radius: 2px;

    &:focus {
        outline: none;
        border: 1px solid var(--primary);
    }

    &[type="radio"] {
        margin-top: 0;
        margin-left: 0;
    }
}

select {
    border: none;
    padding: 8px 15px;
    background-color: var(--inputBg);
    border-radius: 2px;

    &:focus {
        outline: none;
        border: 1px solid var(--primary);
    }
}

.selected {
    border: 2px solid var(--primary);
    border-radius: 20px;
}

.formError {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;

    @include mixin.sm-ut {
        font-size: 13px;
    }

    &.red1 {
        color: var(--red);
    }

    &.red2 {
        color: var(--red2);
    }
}

.labelCheckbox {
    margin-top: 1px;
}

.customCheckbox {
    color: white;

    &:hover {
        cursor: pointer;
    }

    input[type="checkbox"],
    .checked {
        display: none;
    }

    input[type="checkbox"]:checked~.checked {
        display: inline-block;
    }

    input[type="checkbox"]:checked~.unchecked {
        display: none;
    }

}

// Other

li {
    cursor: pointer;
}

.lien {
    font-size: 13px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        opacity: 1.2;
    }
}

.card {
    border-radius: 20px;
    background-color: white;
}

.container {
    width: 100%;
    // padding: 10px;
    padding: 20px;

    @include mixin.sm-ut {
        padding: 30px;
    }

    @include mixin.lg-ut {
        padding: 50px;
    }

    @include mixin.xl-ut {
        padding: 80px;
    }

    @include mixin.xxl-ut {
        padding: 100px;
    }
}

.container-max {
    @extend .container;
    max-width: 1200px;
    margin: auto;

    @include mixin.xxl-ut {
        padding: 100px 0;
    }
}


.btn-shadow {
    box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.44);
}

.active {
    color: var(--primary) !important;

    &>* {
        color: var(--primary) !important;

    }
}
