@use '../../assets/styles/mixins' as mixin;

.notificationBox {
  position: fixed;
  top: 140px;
  left: 0px;
  z-index: 3;
  pointer-events: none;

  .notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--NotificationTextColor);
    width: 260px;
    margin-bottom: 10px;

    opacity: 0;
    padding: 20px 20px;

    animation-name: appear;
    animation-timing-function: ease-in-out;

    i {
      font-size: 24px;
    }

    .text {
      margin-left: 10px;
      font-size: 13px;
      // text-transform: capitalize;
    }

    &.success {
      background-color: rgba(4 105 4 / 85%);
    }

    &.warning {
      background-color: rgb(209 94 12 / 85%);
    }

    &.danger {
      background-color: rgb(123 0 0 / 85%);
    }

  }

  @keyframes appear {
    0% {
      opacity: 100;
    }

    90% {
      opacity: 100;
    }

    100% {
      opacity: 0;
    }
  }
}
