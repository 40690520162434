@use '../../assets/styles/mixins' as mixin;

.headerWrap {
    position: fixed;
    z-index: 1;
    width: 100%;
}

.header {
    a {
        display: flex;
        align-items: center;
    }

    background-color: white;
    width: 100%;
    height: var(--HeaderHeight);

    padding: 0 15px;

    justify-content: space-between;
    align-items: center;

    display: flex;
    flex-flow: row;

    .logoAllset {
        width: 140px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: none;

        @include mixin.sm-ut {
            display: block;
        }

        @include mixin.xl-ut {
            width: 180px;
        }
    }


    .iconAllset {
        width: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include mixin.sm-ut {
            display: none;
        }
    }

    .menu1 {
        display: flex;
        flex-direction: row;

        li {
            display: flex;
            align-items: center;
            padding: 0 10px;

            color: var(--HeaderButtons);
            text-transform: uppercase;
        }

        .logo1 {
            width: 70px;

            &:hover {
                cursor: default;
            }

            @include mixin.md-ut {
                display: block;
            }
        }

    }

    .menu2 {
        display: flex;
        flex-direction: row;

        li {
            padding: 0 10px;
            color: var(--HeaderButtons);
            text-transform: uppercase;

            display: flex;
            flex-direction: row;
            align-items: center;

            &.user {
                text-transform: none;

            }

            small {
                margin-left: 5px;
            }

            .boatInfo {
                display: flex;
                flex-direction: column;
                font-size: 12px;

                .boatName {
                    max-width: 130px;
                    margin-top: -3px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

        }

    }

    .iconFlag {
        width:17px;
    }
    .icons {
        padding:0px 5px !important;
    }

}
