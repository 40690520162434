@use '../../../assets/styles/mixins' as mixin;

.blocCompteur {
    margin-left: 10px;
    text-transform: lowercase;
    display: none;
    @include mixin.sm-ut {
        display: block;
    }
}

.blocCompteurSmartphone{
    margin-left: 10px;
    text-transform: lowercase;
    display: block;
    @include mixin.sm-ut {
        display: none;
    }
}

