@use '../../assets/styles/mixins' as mixin;

.footer {
    .newsletter {
        background-color: var(--FooterNewsletterBg);
        color: var(--FooterNewsletterTextColor);
        text-align: center;
        padding: 5px 0px;
        font-size: 1.4em;
        // margin-top: 50px;
        margin-bottom: 25px;

        span {
            cursor: pointer;
        }

        @include mixin.sm-ut {
            font-size: 1.6em;
        }
    }

    .containerFooter {
        margin: auto;
        width: 100%;

        @include mixin.sm-ut {
            max-width: 500px;
        }

        @include mixin.md-ut {
            max-width: 600px;
        }

        @include mixin.lg-ut {
            max-width: 800px;
        }

        @include mixin.xl-ut {
            max-width: 1000px;
        }

        @include mixin.xxl-ut {
            max-width: 1200px;
        }
    }

    .containerList {
        display: flex;
        flex-direction: column;

        @include mixin.sm-ut {
            flex-direction: row;
            justify-content: space-between;
        }

        li {
            color: var(--FooterTextColor);
            margin-bottom: 5px;

            &.title {
                color: var(--FooterTitleTextColor);
                text-transform: uppercase;
                margin-bottom: 10px;
            }
        }

    }

    hr {
        color: var(--FooterUnderHrTextColor);
        margin-bottom: 0px;
        margin-top: 0;
    }

    .about {
        text-align: center;

        ul:first-child {
            margin-bottom: 20px;
        }

        @include mixin.sm-ut {
            text-align: start;
        }
    }

    .account {
        text-align: center;

        ul:first-child {
            margin-bottom: 20px;
        }
    }

    .service {
        text-align: center;

        ul:first-child {
            margin-bottom: 20px;
        }

        @include mixin.sm-ut {
            text-align: end;
        }
    }

    .containerLinks {
        display: flex;
        flex-direction: column;
        color: var(--FooterUnderHrTextColor);
        padding: 10px;

        @include mixin.md-ut {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .copyright {
        text-align: center;
        font-size: 0.8em;

        span {
            text-decoration: underline;
            margin-left: 10px;

            @include mixin.sm-ut {
                margin-left: 20px;
            }
        }
    }

    .hrMarginBottom {
        margin-bottom: 20px;
    }

    .media {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin-right: 10px;
            font-size: small;
        }

        i {
            margin-left: 5px;
        }
    }

}
